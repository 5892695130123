import React, {Component} from 'react'
import {Segment, Input, Button, Message, Icon} from 'semantic-ui-react'
import API_Root from "../Constants/API_Root";
// import CTAButtonLanding from "../SharedComponents/CTAButtonLanding"
import MobileUpload from "./MobileLandingPage/MobileUpload.svg"
import CRUDActions from "../InfoPages/Features/FeaturesBody/CRUDActions";
import API from "../InfoPages/Features/API";
import HowDoesThisWork from "../SharedComponents/LandingUseCases/HowDoesThisWork"
import UpdatedCTA from "./UpdatedCTA"
import CustomerCarousel from "../InfoPages/CustomerCarousel";
import {getHeroText} from "./getHeroText";
import HowItWorksRow from "./FeaturesTables/HowItWorksRow";
import ImportStep1 from "./Illustrations/ImporterStep1.svg"
import ImportStep2 from "./Illustrations/ImporterStep2.svg"
import ImportStep3 from "./Illustrations/ImporterStep3.svg"
import HowItWorksSegment from "./FeaturesTables/HowItWorksSegment";
import Number1 from "./Illustrations/Number1.svg";
import APIStep1 from "./Illustrations/APIStep1.svg";
import Number2 from "./Illustrations/Number2.svg";
import APIStep2 from "./Illustrations/APIStep2.svg";
import Number3 from "./Illustrations/Number3.svg";
import APIStep3 from "./Illustrations/APIStep3.svg";
import FeatureCard from "./FeatureCard";
import Encrypt from "./Illustrations/Encrypt.svg";
import gdpr from "./Illustrations/gdpr.svg";
import Prem from "./Illustrations/Prem.svg";
import WebFormIllustration from "./Illustrations/WebFormIllustration.svg";
import ImportIllustration from "./Illustrations/ImportIllustration.svg";
import CollaborateIllustration from "./Illustrations/CollaborateIllustration.svg";
import DashboardIllustration from "./Illustrations/DashboardIllustration.svg";
import AppsIllustration from "./Illustrations/AppsIllustration.svg";
import IntegrateIllustration from "./Illustrations/IntegrateIllustration.svg";
import Destinations from "./Illustrations/Destinations.svg";
import ServerCluster from "./Illustrations/ServerCluster.svg";
import EmailSample from "./FeaturesTables/CollaborateTeamTableContent/EmailSample";
import isEmailValid from "./isEmailValid";
import EmailButton from "./MobileLandingPage/EmailButton";
import MobileEmailSample from "./MobileLandingPage/MobileEmailSample";
import {isEven} from "../Constants/isEven";
import HeroText from "./HeroText";
import {isMobile} from "react-device-detect";
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import GunaldiCTA from "./GunaldiCTA";
import LandingConvertSpreadsheetsToAPI from "./Illustrations/LandingConvertSpreadsheetsToAPI.svg"
import MobileLandingConvertSpreadsheetsToAPI from "./MobileLandingPage/MobileLandingConvertSpreadsheetsToAPI.svg"
import MobileFeaturesTable from "./MobileLandingPage/MobileFeaturesTable";
import AutoReportingDesc from "./FeaturesTables/CollaborateTeamTableContent/AutoReportingDesc";
import TeamsDesc from "./FeaturesTables/CollaborateTeamTableContent/TeamsDesc";
import MultipleKeysDesc from "./FeaturesTables/CollaborateTeamTableContent/MultipleKeysDesc";
import MonitoringAccessDesc from "./FeaturesTables/CollaborateTeamTableContent/MonitoringAccessDesc";
import MultipleSources from "./FeaturesTables/APIDatabaseTableContent/MultipleSources";
import CrudActionsDesc from "./FeaturesTables/APIDatabaseTableContent/CrudActionsDesc";
import SimpleDesign from "./FeaturesTables/APIDatabaseTableContent/SimpleDesign";
import QueriesDesc from "./FeaturesTables/APIDatabaseTableContent/QueriesDesc";
import LandingSecurity from "./FeaturesTables/APIDatabaseTableContent/LandingSecurity";
import DataModel from "./FeaturesTables/ImporterTableContent/DataModel";
import ImportWebhooks from "./FeaturesTables/ImporterTableContent/ImportWebhooks";
import PreURL from "./FeaturesTables/ImporterTableContent/PreURL"
import ColMatching from "./FeaturesTables/ImporterTableContent/ColMatching";
import SheetsDestination from "./FeaturesTables/ImporterTableContent/SheetsDestination";
import CustomAppearance from "./FeaturesTables/ImporterTableContent/CustomAppearance";
import GetAnAPI from "./FeaturesTables/ImporterTableContent/GetAnAPI";
import PrewrittenCode from "./FeaturesTables/ImporterTableContent/PrewrittenCode";
import LandingApps from "./Illustrations/LandingApps.svg"
import LandingDashboard from "./Illustrations/LandingDashboard.svg"
import LandingForms from "./Illustrations/LandingForms.svg"
import LandingImport from "./Illustrations/LandingImport.svg"
import LandingReport from "./Illustrations/LandingReport.svg"
import LandingIntegrate from "./Illustrations/LandingIntegrate.svg"
import RequestDemoSegment from "./RequestDemoSegment";
import CachingLayer from "./FeaturesTables/APIDatabaseTableContent/CachingLayer";
import ConnectDomain from "./FeaturesTables/APIDatabaseTableContent/ConnectDomain";
import UtilitiesDisplayIndex from "./UtilitiesDisplay/UtilitiesDisplayIndex"

class MobileLandingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEven: isEven()
        }
    }

    render() {
        const sectionHeaderStyle = {textAlign: "center", paddingBottom: "32px", color: "#161E16", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"};
        const featureMenuHeaderStyle = {fontFamily: "Lato", fontWeight: "700", color: "#161E16", fontSize: "24px", textAlign: "center", marginBottom: "12px"}
        const featureMenuSubheadingStyle = {fontFamily: "Lato", fontWeight: "400", color: "#5E645E", fontSize: "14px", textAlign: "center", marginBottom: "24px"}

        return (
            <div>
                <div style={{textAlign: "center", marginLeft: '20px', marginRight: '20px', marginTop: "50px"}}>
                    <HeroText headerFontSize="250" img/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px', marginTop: "24px"}}>
                    <EmailButton header="Or Email yourself the website link"/>
                </div>
                <div style={{padding: "120px 60px 120px 60px"}}>
                    <div className="row">
                        <div className="col-sm-2">
                            <div style={{fontFamily: "Lato", fontWeight: "700", fontSize: "20px", color: "#161E16", textAlign: "center"}}>
                                Trusted By
                            </div>
                        </div>
                        <div className="col-sm-10">
                            <CustomerCarousel/>
                        </div>
                    </div>
                </div>
                <div style={{backgroundColor: "#F4FBF3", padding: "50px 20px 50px 20px"}}>
                    <div style={sectionHeaderStyle}>
                        Instantly Use Spreadsheets as an <span style={{color: gunaldiStyles.mainGreen}}>API</span> or Database
                    </div>
                    <img src={MobileLandingConvertSpreadsheetsToAPI} style={{width: "100%"}} alt="Convert File to API"/>
                    <div style={{paddingTop: "80px"}}>
                        <MobileFeaturesTable
                            menuDisplay={{
                                "multipleSources": <MultipleSources headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "crudActions": <CrudActionsDesc headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "simpleDesign": <SimpleDesign headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "queriesDesc": <QueriesDesc headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                // "security": <LandingSecurity headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                // "cachingLayer": <CachingLayer headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "connectDomain": <ConnectDomain headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>
                            }}
                            menuItems={[
                                {name:"multipleSources", displayName: "Multiple Sources", header: "Spreadsheets as an API"},
                                {name:"crudActions", displayName: "Execute all CRUD Actions on your files", header: "Spreadsheets as an API"},
                                {name:"simpleDesign",displayName: "Quickstart code in multiple languages", header: "Spreadsheets as an API"},
                                {name:"queriesDesc", displayName: "Run SQL queries on your files", header: "Spreadsheets as an API"},
                                // {name:"security",displayName: "Multiple options to secure your data", header: "Spreadsheets as an API"},
                                // {name:"cachingLayer",displayName: "Caching layer for super fast access", header: "Spreadsheets as an API"},
                                {name:"connectDomain",displayName: "Connect your own domain to use", header: "Spreadsheets as an API"},
                            ]}
                        />
                    </div>
                </div>
                <div style={{textAlign:'center', margin: "120px 0 120px 0"}}>
                    <GunaldiCTA/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px', marginBottom: "120px"}}>
                    <div style={sectionHeaderStyle}>
                        Create a Spreadsheet <span style={{color: gunaldiStyles.orange}}>Importer</span> in Minutes
                    </div>
                    <HowItWorksRow
                        info={[
                            {header: "Customize your importer", body: <img src={ImportStep1} alt="Customize Importer Illustrator" width="100%"/>},
                            {header: "Users upload spreadsheets", body: <img src={ImportStep2} alt="User uploading spreadsheets" width="100%"/>},
                            {header: "Use imported data", body: <img src={ImportStep3} alt="User imported data" width="100%"/>},
                        ]}
                    />
                    <div style={{paddingTop: "80px"}}>
                        <MobileFeaturesTable
                            menuDisplay={{
                                "dataModel": <DataModel headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle} mobile/>,
                                "prewrittenCode": <PrewrittenCode headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "prebuiltURL": <PreURL headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "colMatching": <ColMatching headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "sheetsDestination": <SheetsDestination headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "appearance":<CustomAppearance headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "api":<GetAnAPI headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>,
                                "webhooks": <ImportWebhooks headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle}/>
                            }}
                            menuItems={[
                                {name: "dataModel", displayName: "Data Model", header: "Customize your importer"},
                                {name: "appearance", displayName: "Customize appearance of your importer", header: "Customize your importer"},
                                {name: "sheetsDestination", displayName: "Save file to multiple external systems", header: "Customize your importer"},
                                {name: "prebuiltURL", displayName: "Send importer hosted on our website", header: "Users upload spreadsheets"},
                                {name: "prewrittenCode", displayName: "Build importer with pre-written code", header: "Users upload spreadsheets"},
                                {name: "colMatching", displayName: "Auto map spreadsheet columns to data model", header: "Users upload spreadsheets"},
                                {name: "webhooks", displayName:"Set up a webhook", header: "Use imported data"},
                                {name: "api", displayName:"Access imported data with a REST API", header: "Use imported data"}
                            ]}
                            importer
                        />
                    </div>
                </div>
                <div style={{textAlign:'center', marginBottom: "120px"}}>
                    <GunaldiCTA/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px', marginBottom: "120px"}}>
                    <div style={sectionHeaderStyle}>
                        Query your data in natural language, and much more, with our <span style={{color: gunaldiStyles.blue}}>Utility</span> APIs
                    </div>
                    <div style={{paddingTop: "80px"}}>
                        <MobileFeaturesTable
                            menuDisplay={{
                                "ai": <UtilitiesDisplayIndex headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle} mode="ai" mobile />,
                                "create": <UtilitiesDisplayIndex headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle} mode="create" mobile />,
                                "download": <UtilitiesDisplayIndex headerStyle={featureMenuHeaderStyle} subheadingStyle={featureMenuSubheadingStyle} mode="download" mobile />
                            }}
                            menuItems={[
                                {name: "ai", displayName: "Query with AI", header: "Query your data in natural language"},
                                {name: "create", displayName: "Create File", header: "Create a spreadsheet with a simple API call"},
                                {name: "download", displayName: "Download File", header: "Download .xlsx file with a simple API call"},
                            ]}
                            utility
                        />
                    </div>
                </div>
                <div style={{textAlign:'center', marginBottom: "120px"}}>
                    <GunaldiCTA/>
                </div>
                <div style={{marginBottom: "120px"}}>
                    <div style={{textAlign: "center", paddingBottom: "16px", color: "#161E16", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"}}>
                        Use Cases
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/save-web-form-data.html" target="_blank" className="nostyle">
                            <FeatureCard
                                header="web-forms"
                                desc="Save data from web forms to spreadsheets"
                                img={LandingForms}
                            />
                        </a>
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/import-spreadsheets-on-your-apps.html" target="_blank" className="nostyle">
                            <FeatureCard
                                img={LandingImport}
                                header="import"
                                desc="Add a spreadsheet importer on your website"
                            />
                        </a>
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/automatically-email-spreadsheets-google-sheets.html" target="_blank" className="nostyle">
                            <FeatureCard
                                img={LandingReport}
                                header="report"
                                desc="Automatically send your Google Sheet to your team daily"
                            />
                        </a>
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/dashboard-with-google-sheets.html" target="_blank" className="nostyle">
                            <FeatureCard
                                img={LandingDashboard}
                                header="dashboards"
                                desc="Create a live dashboard with Google Sheets"
                            />
                        </a>
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/react-site-with-no-backend.html" target="_blank" className="nostyle">
                            <FeatureCard
                                img={LandingApps}
                                header="apps"
                                desc="Use spreadsheet data to power your apps"
                            />
                        </a>
                    </div>
                    <div style={{marginBottom: "48px"}}>
                        <a href="/tutorials/react-site-with-no-backend.html" target="_blank" className="nostyle">
                            <FeatureCard
                                img={LandingIntegrate}
                                header="integrate"
                                desc="Combine spreadsheet data from different sources"
                            />
                        </a>
                    </div>
                </div>
                <div style={{textAlign:'center', marginBottom: "120px"}}>
                    <GunaldiCTA/>
                </div>
                <div style={{backgroundColor: "#6FBB98", padding: "100px 20px 100px 20px", marginBottom: "120px"}}>
                    <div style={{textAlign: "center", paddingBottom: "32px", color: "white", fontWeight: "700", fontFamily: "Lato", fontSize: "32px"}}>
                        Security
                    </div>
                    <div>
                        <div style={{marginBottom: "48px"}}>
                            <FeatureCard
                                img={Encrypt}
                                header="data-encryption"
                                desc="Your data is encrypted in-transit using HTTPS/SSL and at rest with AWS"
                                white
                            />
                        </div>
                        <div style={{marginBottom: "48px"}}>
                            <FeatureCard
                                img={gdpr}
                                header="GDPR-compliant"
                                desc="We will never use your data maliciously as spelled out in our Terms of Service"
                                white
                            />
                        </div>
                        <div style={{marginBottom: "48px"}}>
                            <FeatureCard
                                img={Prem}
                                header="on-prem-install"
                                desc="We offer on-prem install and can easily execute a Data Processor agreement"
                                white
                            />
                        </div>
                    </div>
                </div>
                <div style={{textAlign:'center',  marginBottom: "120px"}}>
                    <GunaldiCTA/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px', marginBottom: "48px"}}>
                    <EmailButton header="Email yourself this link to enjoy all the features!"/>
                </div>
                <div style={{margin: "0 20px 48px 20px"}}>
                    <RequestDemoSegment />
                </div>
            </div>
        )
    }
}

export default MobileLandingPage