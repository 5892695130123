import React from 'react'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

export const getHeroText = (isEven, isImporter=false) => {
    if (!isImporter){
        const subheading = <span>Instantly use your spreadsheets as an API. Query your spreadsheets like a Database. Build a spreadsheets importer.<br/><br/>All within <span style={{color: gunaldiStyles.mainGreen}}>minutes.<br/><br/></span>
         </span>

        // const subheading = <span>Use Spreadsheets as a Database. Run SQL Queries. Integrate from multiple sources. All within <span style={{color: gunaldiStyles.mainGreen}}>minutes.</span>
        //  </span>

        if (isEven){
            localStorage.setItem("landingPage", "dev-tools-for-spreadsheets")

            return {
                header: <span>Developer Tools <br/> For <span style={{color: gunaldiStyles.mainGreen}}>Spreadsheets</span></span>,
                subheader: subheading
            }
        } else {
            localStorage.setItem("landingPage", "everything-you-need-to-build")

            return {
                header: <span>Developer Tools to Build<br/> <span style={{color: gunaldiStyles.mainGreen}}>Spreadsheet</span> Features</span>,
                subheader: subheading
            }
        }

        // return {
        //     header: <span>Instantly Use Your<br/><span style={{color: gunaldiStyles.mainGreen}}>Spreadsheets</span> as an API</span>,
        //     subheader: subheading
        // }

    } else {
        const subheading = <span>Collect spreadsheets on your apps. Clean the spreadsheet data. Save data to multiple destinations.<br/><br/>Get started in <span style={{color: gunaldiStyles.orange}}>minutes</span><br/><br/></span>

        return {
            header: <span>Build a <span style={{color: gunaldiStyles.orange}}>Spreadsheet</span><br/>Importer With No Code</span>,
            subheader: subheading
        }
    }
}

