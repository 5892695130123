import React, {Component} from 'react'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";
import {getHeroText} from "./getHeroText";
import GunaldiCTA from "./GunaldiCTA";
import {isEven} from "../Constants/isEven";
import GunaldiHeroFinal from "./Illustrations/GunaldiHeroFinal.svg"
import ImporterHero from "./Illustrations/ImporterHero.svg"

class HeroText extends Component {
    constructor(props){
        super(props);

        this.state = {
            isEven: isEven()
        }
    }

    render() {
        const isImporter = 'importer' in this.props;
        const heroText = getHeroText(this.state.isEven, isImporter);

        return (
            <div>
                <div style={{marginBottom: "24px"}}>
                    <span style={{fontSize: "18px", fontWeight: "400", fontFamily: "Lato", color: "#161E16"}}>Trusted by <span style={{color: isImporter ? gunaldiStyles.orange : gunaldiStyles.mainGreen}}>200+</span> Businesses Worldwide</span>
                </div>
                <h1 style={{fontSize: this.props.headerFontSize.toString() + "%", fontWeight: '900', color: "#161E16", fontFamily: "Lato", marginBottom: "24px"}}>
                    {heroText.header}
                </h1>
                {
                    'img' in this.props ?
                        <div>
                            <img src={isImporter ? ImporterHero : GunaldiHeroFinal} style={{width: '100%', height: 'auto', paddingRight: '5%'}} alt="Hero Spreadsheet Flow"/>
                        </div> : null
                }
                <div style={{fontFamily: "Lato", fontSize: "18px", color: "#5E645E"}}>
                    {heroText.subheader}
                </div>
                <div style={{marginTop: "24px"}}>
                    {
                        isImporter ? <GunaldiCTA importer/> : <GunaldiCTA />
                    }
                </div>
            </div>
        )
    }
}

export default HeroText