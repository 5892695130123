import React, {Component} from 'react'
import SignOutButton from '../../Authentication/UserPages/SignOut/SignOutButton'
import { Menu, Image, Icon } from 'semantic-ui-react'
import images from "../../Constants/Images";
import NavbarItem from './NavbarItem'
import { push } from 'react-router-redux'
import {connect} from "react-redux";
import {modifyNavbarItemClicked} from "../../Actions/ModifyNavbarItemClicked";
import {gunaldiStyles} from "../GunaldiDesignStyles";

class NavbarAuth extends Component {
    constructor(props){
        super(props);

        this.goToURL = this.goToURL.bind(this);
    }

    goToURL(e){
        e.preventDefault();
        this.props.modifyNavbarItemClicked("upload")
        this.props.navigateTo("/upload")
    }

    render() {
        if (window.location.href.includes("update-payment-info") ||
            (window.location.href.includes("useractions") && window.location.href.toLowerCase().includes("verifyemail")) ||
            (window.location.href.includes("import") && window.location.href.includes("upload")) ||
            (window.location.href.includes("import") && window.location.href.includes("embed")) ||
            (window.location.href.includes("/table/"))
        ){
            return (
                null
            )
        } else{
            return (
                <div className="navbarAuthDiv">
                    <Menu secondary stackable>
                        <Menu.Menu position="left">
                            <Menu.Item>
                                <div style={{marginTop: '5px', marginLeft: '10px'}}>
                                    <Image src={images.imageIcon} size="mini"/>
                                </div>
                            </Menu.Item>
                            {/*<Menu.Item>*/}
                            {/*<NavbarItem to="/FAQs" name="FAQs" color="gray" external />*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="upload" name={<span><Icon name="upload" />Upload</span>} color="#9696A0" />*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="quickstart-api" name={<span><Icon name="columns" />Quick API</span>} color="white" />*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="files" name={<span><Icon name="file alternate" />Files</span>} color="#9696A0" />*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="import" name="Import" icon="table" color="#9696A0" />*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="database" name="Database" icon="database" color="#9696A0" />*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item>
                                <span style={{paddingLeft: "16px"}}><NavbarItem to="api" name="API" icon="code" color="#9696A0" /></span>
                            </Menu.Item>
                            <Menu.Item>
                                <NavbarItem to="import" name="Importer" icon="table" color="#9696A0" />
                            </Menu.Item>
                            <Menu.Item>
                                <NavbarItem to="utilities" name="Utilities" icon="wrench" color="#9696A0" />
                            </Menu.Item>
                        </Menu.Menu>
                        <Menu.Menu position="right">
                            {
                                this.props.tier === 'free' ?
                                    <Menu.Item>
                                        <NavbarItem to="paymentModal" name="Upgrade" icon="dollar" color="#9696A0" modal/>
                                    </Menu.Item> : null
                            }
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="demoModal" name="Demo" icon="video" color="white" modal/>*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item>
                                <NavbarItem to="profile" name="Profile" icon="user outline" color="#9696A0"/>
                            </Menu.Item>
                            <Menu.Item>
                                <NavbarItem to="teams" name="Teams" icon="users" color="#9696A0" />
                            </Menu.Item>
                            <Menu.Item>
                                <NavbarItem to="supportModal" name="Support" color="#9696A0" modal icon="envelope outline"/>
                            </Menu.Item>
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="help" name={<span><Icon name="question circle outline" />Help</span>} color="#9696A0" />*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                                {/*<NavbarItem to="feedbackModal" name="Feedback" color="white" modal/>*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item>
                                <SignOutButton style={{marginTop: '5px'}} />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </div>
            )
        }
    }
}


const mapStateToProps = (state) => ({
    tier: state.mainState.tier
});

const mapActionsToProps = {
    navigateTo: push,
    modifyNavbarItemClicked: modifyNavbarItemClicked
};

export default connect(mapStateToProps, mapActionsToProps)(NavbarAuth)