import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Backend_Root from "../../../Constants/API_Backend_Root";
import {modifyFileInformation} from "../../../Actions/ModifyFileInformation";
import Loader from 'react-loader-spinner'
import { Checkbox, Icon } from 'semantic-ui-react'

class ToggleLazyMode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false
        }
    }

    toggleLazyMode = () => {
        this.setState({ fetching: true })

        fetch(API_Backend_Root + "update-file-info-for-api-file/", {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
            },
            body: JSON.stringify({
                pk: this.props.pk,
                fileInfo: {'isLazyMode': !this.props.isLazyMode}
            })
        }).then(res => {
            if (res.status === 200){
                const copiedFileInfo = JSON.parse(JSON.stringify(this.props.fileInformation));
                copiedFileInfo[this.props.pkID]['isLazyMode'] = !this.props.isLazyMode

                this.props.modifyFileInformation(copiedFileInfo)

                this.setState({ fetching: false })
            } else {
                alert("There was an error. Please try again or contact our support if error persists");
                this.setState({ fetching: false })
            }
        }).catch(err => {
            alert("There was an error. Please try again or contact our support if error persists")
            this.setState({ fetching: false })
        })
    }

    render() {
        let fetchDisp = <Loader
            type="TailSpin"
            color="black"
            height="20"
            width="20"
        />;

        if (this.props.display === "toggle"){
            return (
                <div id='privateButtonDiv'>
                    {
                        this.state.fetching ? fetchDisp :
                        <div className="privateButton">
                            <div>
                                <span style={{fontSize: "16px", fontFamily: "Lato", fontWeight: "400"}}>Non-strict mode?</span>
                            </div>
                            <div> </div>
                            <div>
                                <Checkbox checked={this.props.isLazyMode} toggle onChange={this.toggleLazyMode} />
                            </div>
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div>
                    {
                        this.state.fetching ? fetchDisp :
                            <span onClick={this.toggleLazyMode}>{this.props.display}</span>
                    }
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation
})

const mapActionsToProps = {
    modifyFileInformation: modifyFileInformation
}

export default connect(mapStateToProps, mapActionsToProps)(ToggleLazyMode)