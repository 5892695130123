import React, {Component} from 'react'
import { Icon } from 'semantic-ui-react'
import {gunaldiStyles} from "../SharedComponents/GunaldiDesignStyles";

class GunaldiCTA extends Component {
    constructor(props) {
        super(props);

        this.signUp = this.signUp.bind(this);
    }

    signUp(e){
        this.signupLink.click()
    }

    render() {
        const isImporter = 'importer' in this.props;

        return (
            <div>
                <a
                    style={{display: 'none'}}
                    ref={signupLink => this.signupLink = signupLink}
                    href="/signup"
                >Download hidden</a>
                <button style={{padding: "16px 24px",
                    background: isImporter ? gunaldiStyles.orange : "#5499F8",
                    borderRadius: "4px",
                    boxShadow: "none",
                    border: "0"}} className="btnHover" onClick={this.signUp}>
                    <span style={{fontFamily: "Lato", fontWeight: "700", fontSize: "16px", color: "white"}}>Sign Up Free <Icon name="angle right" /></span>
                </button>
            </div>
        )
    }
}

export default GunaldiCTA