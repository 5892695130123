import React, {Component} from 'react'
import { isMobile } from 'react-device-detect'

const allImgInfo = [
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/goodlord.png", link: "https://www.goodlord.co/", name: "Goodlord", desc: "Software making renting simple"},
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/pandazzz.png", link: "https://pandazzz.com/", name: "Pandazzz", desc: "Affordable comfortable mattresses"},
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/AdobeLogo.png", link: "https://www.adobe.com/", name: "Adobe", desc: "Software for design and delivery"},
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/consumertec.png", link: "https://www.consumertec.com/consumertec_br/index_br.html", name: "Consumertec", desc: "R&D Company in Consumer's Benefit Perceptions", width: 1000, height: 454},
    {src: "https://project-static-assets.s3.amazonaws.com/LoveSpreadsheets/ClientLogos/silca.png", link: "https://silca.cc/", name: "Silca", desc: "Premium Bicycle Parts", width: 410, height: 92}
]


class CustomerCarousel extends Component {
    render() {
        const height = 50;

        const logos = allImgInfo.map((x, idx) =>
            {
                return (
                    <div key={idx.toString()} className={isMobile ? "col-sm-2" : ""} style={isMobile ? {marginBottom: "16px"} : null}>
                        <a href={x.link} target="_blank" rel="noopener noreferrer" className="nostyle" style={{textAlign: 'center'}}>
                            <div style={{margin: "0 auto",
                                paddingTop: x.name==="Goodlord" ? "10px" : null
                            }}
                            >
                                {/*<img src={x.src} style={{width: 'width' in x ? (x.width / (x.height / 50)).toString() + "px" : "50%", height: height.toString() + "px", backgroundColor: x.name === "Trip A Deal" ? "black" : null}} alt={x.name + " Logo"}/>*/}
                                <img src={x.src}
                                     style={{width: isMobile ? "60%" : "80%"}}
                                     // style={{width: 'width' in x ? (x.width / (x.height / 50)).toString() + "px" : "50%", height: "50px", backgroundColor: x.name === "Trip A Deal" ? "black" : null}}
                                     alt={x.name + " Logo"}/>
                            </div>
                            {/*<p><b>{x.desc}</b></p>*/}
                        </a>
                    </div>
                )
            }
        )
        return (
            <div>
                {/*<h4 style={{textAlign: 'center', fontFamily: 'museo sans rounded, sans-serif'}}>Trusted by 100+ startups and small businesses</h4>*/}
                <div style={isMobile ? null : {display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr"}} className={isMobile ? "row" : ""}>
                    {logos}
                </div>
                { isMobile ? <div className="col-sm-2" /> : null }
            </div>
        )
    }
}

export default CustomerCarousel