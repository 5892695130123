import React, {Component} from 'react'
import {connect} from 'react-redux'
import {gunaldiStyles} from "../../../SharedComponents/GunaldiDesignStyles";
import FileURL from "../../../FileDnD/FileURL"

class QuickstartLazyMode extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const labelStyle = {backgroundColor: gunaldiStyles.blue,
            padding: "2px 6px 2px 6px",
            borderRadius: "2px",
            fontSize: "12px",
            color: "white", fontWeight: "700", fontFamily: "Lato"};

        return (
            <div>
                <div>
                    <span style={labelStyle}>
                        NON-STRICT MODE
                    </span>
                </div>
                <div style={{ fontSize: "12px"}}>
                    Non-strict mode lets you interact with your Google Sheet using the sheet name and cell names. No database rules apply
                </div>
                <div style={{ marginTop: "16px"}}>
                    <FileURL apiType="create"
                             textPadding="10"
                             pk={this.props.pk}
                             pkID={this.props.pkID}
                             lazyMode
                             displayFileHash={this.props.displayFileHash}
                         />
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(QuickstartLazyMode)