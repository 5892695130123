import React, {Component} from 'react'
import {connect} from 'react-redux'
import { isMobile } from 'react-device-detect'
import FadeIn from 'react-fade-in';
import UseCaseCard from "../../SharedComponents/LandingUseCases/UseCaseCard";
import Forms from "../../SharedComponents/LandingUseCases/Forms.svg"
import PowerApps from "../../SharedComponents/LandingUseCases/PowerApps.svg"
import Dashboard from "../../SharedComponents/LandingUseCases/Dashboard.svg"
import IntegrateSources from '../../SharedComponents/LandingUseCases/IntegrateSources.svg'
import Charts from "../../SharedComponents/LandingUseCases/Charts.svg"
import Code from "../../SharedComponents/LandingUseCases/Code.svg"
import Webdev from "../../SharedComponents/LandingUseCases/Webdev.svg"
import Aws from "../../SharedComponents/LandingUseCases/Aws.svg"
import webform from "../../SharedComponents/LandingUseCases/webform.svg"
import form from "../../SharedComponents/LandingUseCases/form.svg"
import CollaborateIllustration from "../../LandingPage/Illustrations/CollaborateIllustration.svg";
import ImportIllustration from "../../LandingPage/Illustrations/ImportIllustration.svg"
import AppsIllustration from "../../LandingPage/Illustrations/AppsIllustration.svg"
import WebFormIllustration from "../../LandingPage/Illustrations/WebFormIllustration.svg"
import DashboardIllustration from "../../LandingPage/Illustrations/DashboardIllustration.svg"
import IntegrateIllustration from "../../LandingPage/Illustrations/IntegrateIllustration.svg"
import Database from "../../LandingPage/Illustrations/Database.svg"
import ContactSegment from "../../SharedComponents/ContactSegment";

class TutorialsIndex extends Component {
    render() {
        return (
            <div style={{minHeight: "100px", padding: isMobile ? "0 40px 60px 40px" : "0 100px 240px 100px"}}>
                <div className="row" style={{marginBottom: "24px", marginTop: isMobile ? "50px" : "80px"}}>
                    <div className="col-sm-3" />
                    <div className="col-sm-6">
                        <div style={{fontWeight: "700", fontSize: "32px", color: "#161E16", textAlign: "center"}}>
                            Use Cases
                        </div>
                    </div>
                    <div className="col-sm-3" />
                </div>
                <div className="tutorialsPreviewDiv">
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Save Data from Web Forms to Spreadsheets"
                                    url="tutorials/save-web-form-data"
                                    img={WebFormIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Web Forms", writtenBy: "Adhaar Sharma", date: "2020-06-10"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Add a Spreadsheet Importer on your Website"
                                    url="tutorials/import-spreadsheets-on-your-apps"
                                    img={ImportIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Import", writtenBy: "Adhaar Sharma", date: "2021-12-15"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Automatically Email Google Sheets to Your Team Daily"
                                    url="tutorials/automatically-email-spreadsheets-google-sheets"
                                    img={CollaborateIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Report", writtenBy: "Astha Sharma", date: "2021-06-08"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Create a Live Dashboard with Google Sheets"
                                    url="tutorials/dashboard-with-google-sheets"
                                    img={DashboardIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Dashboards", writtenBy: "Adhaar Sharma", date: "2020-05-31"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Use Your Spreadsheet Data in Apps and Websites"
                                    url="tutorials/react-site-with-no-backend"
                                    img={AppsIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Apps", writtenBy: "Astha Sharma", date: "2020-06-09"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Combine data from Google Sheets, Dropbox and others"
                                    url="tutorials/integrate-google-sheets-dropbox-local-files"
                                    img={IntegrateIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Integrate", writtenBy: "Adhaar Sharma", date: "2020-06-03"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Collaborate With Your Fellow Data Analysts"
                                    url="tutorials/share-spreadsheet-data"
                                    img={Dashboard}
                                    landing={false}
                                    tutorialInfo={{heading: "Share", writtenBy: "Adhaar Sharma", date: "2020-06-19"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Learn How To Use an API"
                                    url="tutorials/what-is-an-api"
                                    img={Code}
                                    landing={false}
                                    tutorialInfo={{heading: "Learn", writtenBy: "Clarissa Ng", date: "2020-06-06"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="How to Implement Dynamic HTML Text"
                                    url="tutorials/quickstart-API-display-dynamic-text-HTML"
                                    img={Webdev}
                                    landing={false}
                                    tutorialInfo={{heading: "Apps", writtenBy: "Astha Sharma", date: "2021-03-08"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Make a custom website using Google Sheets & HTML"
                                    url="tutorials/custom-html-sheets"
                                    img={Webdev}
                                    landing={false}
                                    tutorialInfo={{heading: "Apps", writtenBy: "Astha Sharma", date: "2021-02-11"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Securely collect spreadsheets from your clients"
                                    url="tutorials/collect-client-spreadsheets-no-code"
                                    img={ImportIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Import", writtenBy: "Irakli Tchigladze", date: "2022-03-27"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="The Ultimate Guide to HTML Forms"
                                    url="tutorials/ultimate-guide-to-html-forms"
                                    img={webform}
                                    landing={false}
                                    tutorialInfo={{heading: "Web Forms", writtenBy: "Astha Sharma", date: "2021-02-19"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Save user imported files to Google Sheets"
                                    url="tutorials/spreadsheet-uploader-on-website-save-files-to-google-sheets"
                                    img={ImportIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Import", writtenBy: "Irakli Tchigladze", date: "2022-04-08"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Save user imported files to AWS S3"
                                    url="tutorials/spreadsheet-uploader-on-website-save-files-to-aws-s3"
                                    img={ImportIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Import", writtenBy: "Irakli Tchigladze", date: "2022-04-28"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Save user imported files to Dropbox"
                                    url="tutorials/spreadsheet-uploader-on-website-save-files-to-dropbox"
                                    img={ImportIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Import", writtenBy: "Irakli Tchigladze", date: "2022-04-13"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Insert data from spreadsheets to Postgres"
                                    url="tutorials/insert-data-from-spreadsheets-to-postgres"
                                    img={ImportIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Import", writtenBy: "Irakli Tchigladze", date: "2022-05-30"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Insert data from spreadsheets to MySQL"
                                    url="tutorials/insert-data-from-spreadsheets-to-mysql"
                                    img={ImportIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Import", writtenBy: "Irakli Tchigladze", date: "2022-05-26"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Get data from database without a backend"
                                    url="tutorials/get-data-from-database-without-backend-rest-api"
                                    img={Database}
                                    landing={false}
                                    tutorialInfo={{heading: "Database", writtenBy: "Irakli Tchigladze", date: "2022-06-07"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Add data to your database with a REST API in just few lines of code"
                                    url="tutorials/add-data-to-your-database-with-rest-api-no-code"
                                    img={Database}
                                    landing={false}
                                    tutorialInfo={{heading: "Database", writtenBy: "Adhaar Sharma", date: "2022-06-13"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Automatically email your database data as a spreadsheet"
                                    url="tutorials/automatically-email-your-database-data-as-a-spreadsheet-with-no-code"
                                    img={CollaborateIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Database Report", writtenBy: "Adhaar Sharma", date: "2022-06-10"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Save Data from Web Forms to Postgres Database"
                                    url="tutorials/save-web-form-data-postgres-database"
                                    img={WebFormIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Web Forms", writtenBy: "Adhaar Sharma", date: "2020-06-10"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Run SQL Queries on your Spreadsheets"
                                    url="tutorials/how-to-run-sql-queries-on-your-spreadsheets"
                                    img={Database}
                                    landing={false}
                                    tutorialInfo={{heading: "SQL Query Spreadsheet", writtenBy: "Irakli Tchigladze", date: "2022-07-10"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Save Data from Web Forms to MySQL Database"
                                    url="tutorials/save-web-form-data-mysql-database"
                                    img={WebFormIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Web Forms", writtenBy: "Adhaar Sharma", date: "2020-06-10"}}
                                />
                            </FadeIn>
                        </div>
                        {isMobile ? <span><br/><br/></span> : null}
                        <div className="col-sm-4">
                            <FadeIn>
                                <UseCaseCard
                                    title="Instantly display data on a website from a user uploaded spreadsheet"
                                    url="tutorials/instantly-display-data-on-website-from-user-uploaded-spreadsheet"
                                    img={ImportIllustration}
                                    landing={false}
                                    tutorialInfo={{heading: "Import", writtenBy: "Irakli Tchigladze", date: "2022-08-10"}}
                                />
                            </FadeIn>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<FadeIn>*/}
                                {/*<UseCaseCard*/}
                                    {/*title="How to Build a Custom Web App Part 1: Build a Form & Display the Data"*/}
                                    {/*url="tutorials/custom-web-app-forms-display"*/}
                                    {/*img={form}*/}
                                    {/*landing={false}*/}
                                    {/*tutorialInfo={{heading: "Custom Web App - Part 1", writtenBy: "Astha Sharma", date: "2021-05-27"}}*/}
                                {/*/>*/}
                            {/*</FadeIn>*/}
                        {/*</div>*/}
                        {/*{isMobile ? <span><br/><br/></span> : null}*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<FadeIn>*/}
                                {/*<UseCaseCard*/}
                                    {/*title="How to Build a Custom Web App Part 2: Build a Login Form, Update & Delete"*/}
                                    {/*url="tutorials/custom-web-app-forms-login-update-delete"*/}
                                    {/*img={webform}*/}
                                    {/*landing={false}*/}
                                    {/*tutorialInfo={{heading: "Custom Web App - Part 2", writtenBy: "Astha Sharma", date: "2021-06-08"}}*/}

                                {/*/>*/}
                            {/*</FadeIn>*/}
                        {/*</div>*/}
                        {/*{isMobile ? <span><br/><br/></span> : null}*/}
                        {/*<div className="col-sm-4">*/}
                            {/*<FadeIn>*/}
                                {/*<UseCaseCard*/}
                                    {/*title="How to Build a Custom Web App Part 3: Deploying Using AWS"*/}
                                    {/*url="tutorials/custom-web-app-deploying"*/}
                                    {/*img={Aws}*/}
                                    {/*landing={false}*/}
                                    {/*tutorialInfo={{heading: "Custom Web App - Part 3", writtenBy: "Astha Sharma", date: "2021-06-21"}}*/}

                                {/*/>*/}
                            {/*</FadeIn>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>
                <ContactSegment
                    header="Don't see your use case here?"
                    desc={<span>No worries, contact us! We can help you figure out if API Spreadsheets<br/>can support your use case. Or help you determine the best solution</span>}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(TutorialsIndex)