import React, {Component} from 'react'
import { isBrowser } from 'react-device-detect'
import getURLParam from "../../Constants/getURLParam";
import ThinFooterIndex from "./ThinFooterIndex";
import FullFooter from "./FullFooter"
import MobileFooter from "./MobileFooter";

class FooterIndex extends Component {
    render() {
        if (
            (window.location.href.includes("import") && window.location.href.includes("embed")) ||
            (window.location.href.includes("/table/"))
        ){
            return null
        } else {
            if (isBrowser) {
                const urlParam = getURLParam(window.location.href);

                if (urlParam.includes("files") || urlParam.includes("profile") || urlParam.includes("upload")){
                    return(
                        <ThinFooterIndex/>
                    )
                }
                else{
                    return(
                        <FullFooter />
                    )
                }
            }
            else{
                return(
                    <MobileFooter/>
                )
            }
        }
    }
}

export default FooterIndex