import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toggleModal} from "../Actions/ToggleModal";
import {modifyFileURL} from "../Actions/ModifyFileURL";
import { Button, Icon, Label } from 'semantic-ui-react';
import {getFileURL} from "../Constants/getFileURL";
import {toggleToast} from "../Actions/ToggleToast";
import {getConnectedDomainLink} from "../Constants/getConnectedDomainLink";

class FileURL extends Component {
    constructor(props){
        super(props);

        this.copyURL = this.copyURL.bind(this);
    }

    copyURL(e){
        // e.target.focus();
        navigator.clipboard.writeText(this.getURL())
        document.execCommand("copy")
        window.getSelection().removeAllRanges();

        this.props.toggleToast({show: true, message: "URL Copied!", type: "success"})
    }

    getURL = () => {
        const isImport = 'import' in this.props;
        let url = "";
        if (isImport){
            let baseURL;
            const connectedDomainLink = getConnectedDomainLink(this.props.connectedDomains);
            if (connectedDomainLink !== null){
                baseURL = connectedDomainLink
            } else{
                baseURL = window.location.origin
            }

            url = baseURL + "/import/upload/" + this.props.importHash
        } else {
            if ("quickstart" in this.props){
                url = this.props.url
            } else{
                url = getFileURL(this.props.fileInformation, this.props.displayFileHash, this.props.pkID, this.props.outputFormat, this.props.apiType, this.props.rowLen, this.props.rows, this.props.readQuery, this.props.count)
            }
        }

        return url
    }

    render() {
        const isImport = 'import' in this.props;
        const customImport = 'importCustom' in this.props;

        let url = this.getURL()

        if ('lazyMode' in this.props){
            url = url.replace("/data/", "/data/non-strict/")
        }

        let width = "100%"
        if (customImport){
            width = (url.length * 8).toString() + "px";
        }

        let color = "orange";
        let requestType = "POST"

        if (this.props.apiType === 'readAction' || this.props.apiType === 'deleteAction'){
            color = '#9b870c'
            requestType = "GET"
        }

        let allClassName;
        if (customImport){
            allClassName = "customImportFileURL"
        } else {
            allClassName = "codeFileURL"
        }

        if (["readActionParameters", "deleteActionParameters", "readAction", "createAction", "deleteAction", "updateAction"].includes(this.props.tutorialStep)){
            allClassName += " tutorialHighlighted";
        }

        if ("isImportSegment" in this.props){
            return (
                <span className="ellipses importSegmentRowFileURLDiv">
                    <p className="wordwrap apiURLText ellipses importSegmentRowFileURLDiv" id="apiURLInput">
                        {'message' in this.props ? <span style={{marginRight: "10x"}}>{this.props.message}</span> : null}
                        {'message' in this.props ? <span style={{marginRight: "20x"}}> </span> : null}
                        <Icon name="copy outline"
                              className="fileIDLabelIcon importSegmentRowFileURLDiv"
                              color="green"
                              style={{cursor: "pointer"}}
                              onClick={this.copyURL}
                        />
                        {url}
                    </p>
                </span>
            )
        } else {
            return (
                <div className={allClassName} id="fileURL">
                    <div style={{marginBottom: "6px"}}>
                    <span>
                        <span style={{verticalAlign: "middle", fontSize: "16px", color: "#161E16", fontWeight: "700", fontFamily: "Lato"}}><i className='bx bx-window-alt' style={{color: "#9696A0", fontSize: "20px", verticalAlign: "middle"}} /> <span style={{paddingLeft: "4px", marginTop: "5px"}}>{isImport ? "Import URL for People to Upload Files" : "API URL"}</span></span>
                        {isImport ? null : <span style={{ backgroundColor: color, color: "white", fontSize: "85%", padding: "0 4px 0 4px", fontWeight: "700", marginLeft: "6px"}}>{requestType}</span>}
                    </span>
                    </div>
                    <p className="wordwrap apiURLText" id="apiURLInput">
                        <Icon name="copy outline"
                              className="fileIDLabelIcon"
                              color="green"
                              style={{cursor: "pointer"}}
                              onClick={this.copyURL}
                        />
                        {url}
                    </p>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    fileInformation: state.mainState.fileInformation,
    tutorialStep: state.mainState.tutorialStep,
    connectedDomains: state.mainState.connectedDomains
});

const mapActionsToProps = {
    toggleModal: toggleModal,
    modifyFileURL: modifyFileURL,
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(FileURL)